import React, { useEffect, useState, useRef } from "react";
import twoWayVideo from "../../assets/images/twoWayVideo.svg";
import defultUser from "../../assets/images/defultUser.png";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderHome from "../dashboard/commons/headerHome";
import Footer from "../../component/footer";
import { siteKey } from "../../constant/default";
import { connect, useDispatch, useSelector } from "react-redux";
import { getPublicHomPageSpData, getUserCategories } from "../../services/homeService";
import { toast } from "react-toastify";
import { publicData, reset_public_scroll_position, reset_set_public_page, set_public_page_scroll_position, set_public_page_selected_category, set_public_persist_page_no, set_public_sp_data, set_total_number_of_sp } from "../../reducer/publicDataSlice";
import { CircularProgress } from "@mui/material";
import phoneDarlingsWebBanner from "../../assets/images/phoneDarlingsWebBanner.gif";
import { useQuery } from "../../utils/useQueryHoook";
import ReCAPTCHA from "react-google-recaptcha";
import { affiliatesVisits } from "../../services/authService";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faHeart, faMagnifyingGlass, faMessage, faPhone, faTty, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function PdMain(props: any) {
  const persistDispatch = useDispatch()
  const navigate = useNavigate();
  const [userList, setUserList] = useState<any>([])
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const restorationRef = React.useRef<null | HTMLDivElement>(null);
  const abortControllerRef = useRef<AbortController | null>();
  const query = useQuery();
  const refCode = query.get('refcode');
  const captchaRef = useRef(null);
  const location = useLocation()
  const [hideSeeMoreButton, setHideSeeMoreButton] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [totalNumberOfSp, setTotalNumberOfSp] = useState(0)
  const [searchText, setSearchText] = useState<string | null>(null)
  const [categories, setcategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({
    name: null,
    index: null,
    isDropDownOpen: false,
    subcategories: null
  })
  const [filterParam, setFilterParam] = useState<any>({
    category: null,
    subcategory: null
  })
  const [selectCat, setSelectCat] = useState<any>([]);
  const [tempSelectCat, setTempSelectCat] = useState<any>([]);
  const [advanceFilterOpen, setAdvanceFilterOpen] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string | null>(null)
  const [refetchClientData, setRefetchClientData] = useState(false);
  const { publicSpData, publicPageScrollPosition, publicPagePersistPageNo, publicPageSelectedCategory, publicPageTotalNumberOfSp } = useSelector(publicData);
  const [isFiltered,setIsFiltered]= useState<boolean>(false)
  const consultantListRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (consultantListRef.current && advanceFilterOpen) {
      consultantListRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return (() => {
      document.body.style.overflow = "auto"
    })
  }, [advanceFilterOpen])


  useEffect(() => {
    if (totalNumberOfSp && userList && totalNumberOfSp === userList?.length) {
      setHideSeeMoreButton(true);
    }
  }, [userList, totalNumberOfSp])

  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    setSearchParam(category)
  },[location])

  useEffect(() => {
    const { dispatch } = props;

    if (refCode) {
      let token: any = captchaRef?.current;
      let tokendata = token?.executeAsync();
      tokendata.then((res: any) => {
        if (res) {
          dispatch(affiliatesVisits(refCode, res)).then(() => { }).catch(() => { })
        }
      })
    }
  }, [refCode])

  useEffect(() => {
    const onBeforeUnload = (ev: any) => {
      window.scrollTo(0, 0)
      persistDispatch(set_public_persist_page_no(null))
      persistDispatch(set_public_sp_data(null))
    }

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      if (window?.location?.pathname === "/") {
        navigate("/");
      }
    });

    return (() => window.removeEventListener("popstate", () => {
      if (window?.location?.pathname === "/") {
        navigate("/");
      }
    }))
  }, []);

  useEffect(() => {
    if (filterParam.category || filterParam.subcategory) {
      getSpData(true)
    }
  }, [filterParam.category, filterParam.subcategory])


  const getSpData = (resetClientsDetails?: boolean) => {

    if (abortControllerRef.current) {
      return;
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;

    const excludeIds = userList.map((user: any) => {
      return user.id
    })

    setCardLoading(true);
    const { dispatch } = props;
    const body: any = {
      requireTotalCount: true,
      pageNo: resetClientsDetails ? 1 : page ? page : 1,
      pageSize: 20,
      searchText: searchText ? searchText : '',
      categories2: selectCat
    }

    if (!resetClientsDetails) {
      body.excludeIds = excludeIds
    }

    dispatch(getPublicHomPageSpData(body)).then((res: any) => {
      setTotalNumberOfSp(res?.data?.totalCount)
      if (res.data.data.length === 0 && !resetClientsDetails) {
        // setNoMoreData(true);
      } else if (resetClientsDetails && res.data.data.length === 0) {
        setUserList([]);
        setPage(1);
      } else if (resetClientsDetails && res.data.data.length !== 0) {
        setUserList(res.data.data);
        setPage(2);
      } else {
        setUserList((oldArray: any) => [...oldArray, ...res.data.data]);
        setPage(page + 1);
      }
    }).catch((err: any) => {
      toast.error("Something Went Wrong...!!!")
    }).finally(() => {
      setCardLoading(false);
      setIsLoading(false)
      abortControllerRef.current = null;
      setIsLoading(false)
    })
  }

 
  const handleAllCategory = async () => {
    setFilterParam({
      category: null,
      subcategory: null,
    });
    setSelectedCategory({
      name: null,
      index: null,
      isDropDownOpen: false,
      subcategories: null,
    });
    setSelectCat([]);
    const url = `/`;
    navigate(url);
    setRefetchClientData(true)
  };

  useEffect(() => {
    if (refetchClientData) {
      getSpData(true)
    }
    if(selectCat.length){
      setRefetchClientData(false)
    }
  }, [refetchClientData, selectCat])
  
  const getCategoriesList = () => {
    setIsLoading(true)
    const { dispatch } = props;
    dispatch(getUserCategories(true))
      .then((res: any) => {
        setcategories(res?.data);
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
      });
  };

  const onCategoryClick = (index: any, subcategories: any) => {
    if(advanceFilterOpen){
      setAdvanceFilterOpen(false)
    }
    setSelectCat([])
    const selectedCat = categories[index];
    let url = `?category=${encodeURIComponent(selectedCat.name)}`;
    navigate(url)
    setRefetchClientData(false)
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    if(category) {
      handleCheckCat(category)
    }
  }, [location])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const subcategory = queryParams.get('sub');
    abortControllerRef.current = null;
    if (category) {
      setFilterParam({ category: category, subcategory })
    }
    if (category && subcategory) {
      setFilterParam({ category: category, subcategory: subcategory })
    }
  }, [selectedCategory]);


  useEffect(() => {
    if (userList.length) {
      persistDispatch(set_public_sp_data(userList))
    }

    if (page > 1) {
      persistDispatch(set_public_persist_page_no(page))
    }
  }, [page])

  const myPromise = new Promise((resolve, reject) => {
    if (publicPagePersistPageNo && publicSpData) {
      resolve({ userData: publicSpData, pageNo: publicPagePersistPageNo })
    } else {
      reject("")
    }
  });

  useEffect(() => {
    if (!filterParam?.category || !filterParam?.subCategory) {
      myPromise.then((res: any) => {
        setUserList(res.userData)
        setPage(res?.pageNo)
      }).catch(() => {
        setIsLoading(true)
        getSpData();
      })
    }
  }, [])

  useEffect(() => {
    if (searchText !== null) {
      setIsLoading(true)
      setUserList([])
      setPage(1)
      setIsLoading(true)
      getSpData(true)
    }
  }, [searchText])


  const onRedirectProfile = (data: any) => {
    persistDispatch(set_public_page_scroll_position(data?.id))
    persistDispatch(set_public_page_selected_category(selectCat))
    persistDispatch(set_total_number_of_sp(totalNumberOfSp))
    navigate(`/user/profileDetails/${data?.uniqueUsername}`);
  };

  useEffect(() => {
    if (publicPageScrollPosition && restorationRef && restorationRef?.current && userList?.length) {
      setSelectCat(publicPageSelectedCategory)
      setTotalNumberOfSp(publicPageTotalNumberOfSp)

      restorationRef?.current?.scrollIntoView({ behavior: 'instant' as any, block: 'center', inline: 'start' })
      persistDispatch(reset_public_scroll_position())
    }
  }, [publicPageScrollPosition, restorationRef, userList, publicPageSelectedCategory, publicPageTotalNumberOfSp]);

  const onChange = (e: any) => {
    setSearchText(e.target.value)
  };

  const debouncedOnChange = debounce(onChange, 1000);

  const handleCheckCat = (name: any) => {
    const targetState = advanceFilterOpen ? tempSelectCat : selectCat;
    const setState = advanceFilterOpen ? setTempSelectCat : setSelectCat;
  
    if (targetState.some((catName: any) => catName?.name === name)) {
      setState(targetState.filter((catName: any) => catName?.name !== name));
    } else {
      setState([...targetState, { name: name, subcategories: [] }]);
    }
  };
  
  const handleCheckSubCat = (catName: string, subCatName: string) => {
    const targetState = advanceFilterOpen ? tempSelectCat : selectCat;
    const setState = advanceFilterOpen ? setTempSelectCat : setSelectCat;
  
    let updatedCat = [...targetState];
  
    if (!targetState.some((cat: any) => cat?.name === catName)) {
      updatedCat.push({ name: catName, subcategories: [subCatName] });
    } else {
      updatedCat = targetState.map((cat: any) => {
        if (catName === cat?.name) {
          const newSubcategories = cat.subcategories.includes(subCatName)
            ? cat.subcategories.filter((subCat: any) => subCat !== subCatName)
            : [...cat.subcategories, subCatName];
  
          return {
            ...cat,
            subcategories: newSubcategories,
          };
        } else {
          return cat;
        }
      });
    }
  
    setState(updatedCat);
  };

  const handleApplyFilterOpen = () => {
    if (advanceFilterOpen) {
      setSelectCat(tempSelectCat);
      setTempSelectCat([]);
      setAdvanceFilterOpen(false);
      setTotalNumberOfSp(0);
      setHideSeeMoreButton(false);
      setRefetchClientData(true);
      setIsFiltered(true);
    } else {
      setAdvanceFilterOpen(true);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    if(category) {
      handleCheckCat(category)
    }
  }, [location])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
  if(!advanceFilterOpen){
    if (selectCat &&  selectCat.length === 1 &&  !selectCat[0]?.subcategories?.length) {
      if (category && selectCat[0]?.name === category) {
        getSpData(true)
      } else {
        getSpData(false)
      }
    }
  }
    
    if(selectCat.length > 1){
      navigate('/');
    }
  }, [selectCat]);

  const handleAdvanceFilterPopup = () => {
    if (isFiltered && advanceFilterOpen && !selectCat.length) {
      setSelectCat([])
      setIsFiltered(false)
    } else if (!isFiltered && selectCat.length) {
      setSelectCat([])
    }
    if (!advanceFilterOpen) {
      setTempSelectCat([...selectCat]);
    } else {
      setTempSelectCat([]);
    }
    setAdvanceFilterOpen(!advanceFilterOpen)
  }

 
  const handleUndoAll = () => {
    setSelectCat([]);
    setTempSelectCat([]);
    setIsFiltered(false);
    if (selectCat.length > 0) {
      setRefetchClientData(true);
    }
  };
  
  return (
    <div className="bg-[#F8F3FD] min-h-screen">
      <HeaderHome />
      <div
        className={`lg:mt-[-70px]`}
      >
        <img src={phoneDarlingsWebBanner} width="100%" />
      </div>
      <div className="body-section">
        <div className={`min-h-screen`}>
          <div ref={consultantListRef} className="md:block antialiased min-h-screen text-gray-900 md:px-6 py-6 max-h-[60%] pb-2">
            <div className="">
              {categories?.length ?
                <div className="flex justify-between gap-4 px-3 md:px-0">
                  <div className="flex max-w-full overflow-y-auto gap-2 items-center smallScroll pb-2">
                    <div className={`cursor-pointer text-md min-w-fit px-6 border-2 border-primary flex items-center font-semibold place-content-center py-4 rounded-full text-[14px] w-44 mr-3 ${!selectCat.length ? "bg-primary text-white" : "bg-transparent text-primary"}`} onClick={() => handleAllCategory()}>
                      All categories
                    </div>
                  {categories.map((category: any, index: any) => {
                            if (category?.showOnTop) {
                              const currentCat = selectCat.length === 1 && searchParam && selectCat?.find((selectedCat: any) => selectedCat?.name === category?.name)
                              return (
                                <div key={category?.id} className={`group cursor-pointer min-w-fit border-2 border-primary flex font-semibold place-content-center py-4 rounded-full text-[14px] w-44 mr-3 ${ currentCat?.name ? 'bg-primary' : 'bg-transparent'}`} onClick={() => onCategoryClick(index, category?.subcategories)}>
                                  <div className={`text-primary font-semibold px-6 text-md flex items-center ${currentCat?.name? 'text-white' : 'text-primary'}`}>
                                    <div>{category.name}</div>
                                  </div>
                                </div>
                              )
                            }

                            return null
                          })}
                  </div>
                  <div className="pb-2">
                    <div className={`relative cursor-pointer  ${isFiltered && selectCat.length > 0 && !searchParam ? 'bg-primary text-white': 'bg-white text-primary'} font-semibold px-6 text-md flex items-center h-full rounded-lg border-2 border-primary`} onClick={() => handleAdvanceFilterPopup()}>
                      <div className="w-max">Advanced Filter</div>
                      <div><FontAwesomeIcon icon={advanceFilterOpen ? faAngleUp : faAngleDown} className="mt-1 ml-2 font-bold text-lg" /></div>
                    </div>
                    <div className={` max-h-[80dvh] overflow-auto smallScroll shadow-2xl  right-0 mx-3 md:mx-0 md md:right-6 rounded text-primary mt-4 bg-white dropdown transition-opacity duration-900 absolute z-[999] ease-linear ${advanceFilterOpen ? 'block' : 'hidden'}`}>
                      <div className='sticky top-0  h-16 flex w-full items-center border-2 border-primary'>
                        <button className='w-full bg-primary text-white rounded-t-lg-lg h-full font-medium' onClick={() => handleApplyFilterOpen()}>Apply filter</button>
                        {(selectCat.length > 0 || tempSelectCat.length > 0) && (
                          <button
                            className='w-full bg-white font-medium text-primary border-white h-full'
                            onClick={() => handleUndoAll()}
                          >
                            Undo All
                          </button>
                        )}
                      </div>
                      <div className="px-6 pr-2 py-3">
                        <div className="grid grid-cols-2 gap-4">
                          {categories.map((cat: any, index: any) => {
                            const currentSelection = advanceFilterOpen ? tempSelectCat : selectCat;
                            const currentCat = currentSelection?.find((selectedCat: any) => selectedCat?.name === cat?.name)

                            return (
                              <>
                                <div
                                  key={cat?.id}
                                  className=" mb-1 text-[#37085B]"
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      name={cat?.name}
                                      placeholder="Profile Description"
                                      id={cat?.name + index}
                                      onChange={(event: any) => handleCheckCat(cat?.name)}
                                      checked={currentCat?.name ? true : false}
                                      className={`border-[#37085B] h-5 w-5 rounded-lg border bg-white checked:bg-[#37085B] checked:border-[#37085B] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"}`}
                                    />
                                    <label
                                      className={`text-sm font-semibold inline-block text-[#37085B]`}
                                      htmlFor={cat?.name + index}
                                      style={{ fontFamily: "Montserrat" }}
                                    >
                                      {cat?.name}
                                    </label>
                                  </div>

                                  <div className="ml-6">
                                    {cat?.subcategories &&
                                      cat?.subcategories?.map((subCat: any, index: number) => {
                                        return (
                                          <div key={index} className="flex items-center mt-2">
                                            <input
                                              type="checkbox"
                                              name={subCat?.name}
                                              placeholder="Profile Description"
                                              id={subCat + index}
                                              onChange={(event: any) => handleCheckSubCat(cat?.name, subCat)}
                                              checked={currentCat?.subcategories.includes(subCat) ? true : false}
                                              className={`border-[#37085B] h-5 w-5 rounded-lg border bg-white checked:bg-[#37085B] checked:border-[#37085B] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"}`}
                                            />
                                            <label
                                              className={`text-sm font-semibold inline-block text-[#37085B]`}
                                              htmlFor={subCat + index}
                                              style={{ fontFamily: "Montserrat" }}
                                            >
                                              {subCat}
                                            </label>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div >
                      </div>
                    </div>
                  </div>

                </div>
                : null
              }

              <div className="mt-2 w-full px-3 md:px-0">
                <div className="flex items-center px-6 py-2 rounded-full border-2 border-primary w-full bg-[#F8F3FD]">
                  <input
                    className="grow placeholder-primary placeholder-opacity-70 bg-[#F8F3FD] outline-none bottom-0 focus:outline-none text-lg font-semibold text-primary"
                    placeholder="Search here..."
                    onChange={debouncedOnChange}
                  />
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="text-primary" />
                </div>
              </div>

              {userList.length === 0 && !isLoading ? (
                <div className="text-primary flex justify-center px-4 font-bold text-2xl mt-8 font-[Montserrat]">
                  No client found.
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-x-4 gap-y-8 2xl:gap-x-4 2xl:gap-y-8 mt-4">
                    <>
                      {userList.map((client: any) => {
                        return (
                          <div className="w-full "
                            style={{ scrollMarginTop: "30px" }}
                            onClick={() => onRedirectProfile(client)}
                            ref={publicPageScrollPosition === client?.id ? restorationRef : null}
                          >
                            <div className="w-full max-w-[360px] h-[450px] mx-auto rounded-md shadow-lg bg-white cursor-pointer">
                              {client.profileImageUrl === null ||
                                client.profileImageUrl === "" ? (
                                <div className="w-[290px] h-[290px]">
                                  <img
                                    className="pt-[82px] pl-[70px]"
                                    src={defultUser}
                                    alt="product"
                                  />
                                </div>
                              ) : (
                                <div className="relative h-[320px] overflow-hidden">
                                  <img
                                    className="w-full min-h-[320px] rounded-t-md"
                                    src={client.profileImageUrl}
                                    alt="client profile"
                                  />
                                  <div className="absolute cursor-pointer right-3 top-3">
                                    {client.isFavorite ? (
                                      <FontAwesomeIcon icon={faHeart} className="text-3xl text-primary" />
                                    ) : (
                                      <FontAwesomeIcon icon={regularHeart as IconProp} className="text-3xl text-white" />
                                    )}
                                  </div>
                                </div>
                              )}
                              <div
                                className="px-4 py-2"
                              // onClick={() => onRedirectProfile(client)}
                              >
                                <div className="text-base font-[Montserrat] font-semibold flex items-center">

                                  <span
                                    className={`w-[12px] h-[12px] rounded-full ${client.status === "Available"
                                      ? "bg-[#20B514]"
                                      : client.status === "Busy" ||
                                        client.status === "DoNotDisturb"
                                        ? "bg-[#E99312]"
                                        : "bg-[#EBEBEB]"
                                      } mr-2`}
                                  ></span>
                                  <div className="max-w-[90%] line-clamp-1 break-all">
                                    {client.username}
                                  </div>
                                </div>
                                <p
                                  className="text-[#000000] font-[Montserrat] text-[14px] overflow-hidden min-h-[45px]"
                                  style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {client.description}
                                </p>
                                <div>Services</div>
                                <div className="grid grid-cols-5 gap-2">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faMessage} className="text-primary" />
                                    <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                      $
                                      {
                                        client?.communication?.shortMessageUnitPrice.toFixed(2) ?? 0
                                      }
                                    </p>
                                  </div>
                                  {client?.enablePhoneCall &&
                                    <div className="flex items-center">
                                      <FontAwesomeIcon icon={faTty} className="text-primary" />

                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {
                                          client?.communication?.phoneCallUnitPrice.toFixed(2) ?? 0
                                        }
                                      </p>
                                    </div>
                                  }

                                  {client?.enableAudioCall &&
                                    <div className="flex items-center">
                                      <FontAwesomeIcon icon={faPhone} className="text-primary" />

                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {
                                          client?.communication?.audioCallUnitPrice.toFixed(2) ?? 0
                                        }
                                      </p>
                                    </div>
                                  }


                                  {client?.enableOneWayVideoCall &&
                                    <div className="flex items-center">
                                      <FontAwesomeIcon icon={faVideo} className="text-primary" />

                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {
                                          client?.communication?.videoCallOneWayUnitPrice.toFixed(2) ?? 0
                                        }
                                      </p>
                                    </div>
                                  }

                                  {client?.enableTwoWayVideoCall &&
                                    <div className="flex items-center">
                                      <img
                                        src={twoWayVideo}
                                        className="w-[18px] h-[18px]"
                                        alt="videochat"
                                      />
                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {
                                          client?.communication?.videoCallTwoWayUnitPrice.toFixed(2) ?? 0
                                        }
                                      </p>
                                    </div>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>

                  {(!hideSeeMoreButton && !cardLoading) ?
                    <div className="w-full flex justify-center mt-3">
                      <div className={`cursor-pointer text-md min-w-fit mb-3 px-6 border-2 border-primary flex items-center font-semibold place-content-center py-4 rounded-full text-[14px] w-44 mr-3 bg-transparent text-primary`} onClick={() => getSpData()}>
                        See more
                      </div>
                    </div>
                    : null
                  }

                  {(cardLoading && !isLoading) ?
                    <div className="w-full flex justify-center py-2">
                      <CircularProgress style={{ color: "#37085B" }} thickness={7} />
                    </div>
                    : null
                  }
                </>
              )}
              {/* <div className="mt-16 mb-6 w-100 flex justify-center" onClick={redirectRegister}>
                <button className="border border-2 border-primary rounded-full py-2 px-4 text-primary">
                  Load more
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          {" "}
          <ReCAPTCHA
            sitekey={siteKey}
            ref={captchaRef}
            size="invisible"
          />
        </div>
      </div>

      <Footer />
      {/* {showModal === "yes" && <ConformationModal />} */}

      {/* {welcomePopup ?
        <WelcomePopup
          onClickOnCancel={welcomePopupClose}
        /> : null
      } */}

    </div>
  );
}

export default connect()(PdMain);
